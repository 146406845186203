import zxcvbn from 'zxcvbn'
import { globalFunctions } from './globalFunctions'

export const passwordFunctions = {

  ///////////////////////////////////////////////////////////
  //Checks Strength of Password
  ///////////////////////////////////////////////////////////

  isPasswordStrength(value) {
    if (!globalFunctions.isNullOrEmpty(value)) {
      let result = zxcvbn(value)

      switch (result.score) {
        case 0:
          return 'Very Weak Password';

        case 1:
          return 'Weak Password';

        case 2:
          return 'Somewhat Weak Password';
      }
    }
    return true
  }
}
